<template>
  <div class="view-case">
    <div class="header-img">
      <img class="w100" src="@/assets/images/all/pc-icon7.png" alt="" srcset="">
    </div>
    <div class="center-content">
      <div>
        <van-list class="img-list flex-s" v-model="loading" :finished="finished" loading-text="loading" finished-text="finished" @load="onLoad">
          <div @click="getCaseDetail(item)" v-for="item in caseList" :key="item.id" class="img-item point">
            <img v-if="item.images&&item.images.length>0" class="h100 w100" :src="onlineUrl+item.images[0]" alt="" srcset="">
            <div class="title-box flex-c">
              <div class="title">
                {{item.title}}
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <van-overlay :show="caseItemShow" v-if="caseDetail">
      <div class="case-detail fixed-c">
        <div class="left-banner">
          <el-carousel class="banner-list" :interval="5000" trigger="click">
            <el-carousel-item class="banner-item" v-for="item,index in caseDetail.images" :key="index">
              <img class="w100 h100" :src="onlineUrl+item" alt="" srcset="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="right-content">
          <div class="c-title">
            {{caseDetail.title}}
          </div>
          <div class="c-line"></div>
          <div class="c-text">
            {{caseDetail.describe}}
          </div>
        </div>
        <div @click="caseItemShow=false" class="close-icon">
          <img class="w100 point" src="@/assets/images/all/pc-close.png" alt="" srcset="">
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getCaseListApi } from '@/network/api';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["onlineUrl"]),
  },
  data() {
    return {
      caseItemShow: false,
      caseList: [],
      caseDetail: null,
      loading: false,
      finished: false,
      page: 1,
      TotalItems: 1
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.getCaseListFun();
    },
    // async getCaseListFun() {
    //   const params = {
    //     page: this.page
    //   };
    //   const res = await getCaseListApi(params);
    //   this.caseList = res?.data?.data || [];
    //   this.TotalItems = res.data.total;
    //   console.log(123, res);
    // },
    getCaseDetail(item) {
      this.caseDetail = item;
      this.caseItemShow = true;
    },
    async onLoad() {
      if (this.caseList.length !== 0) {
        this.page += 1;
      }
      const params = {
        page: this.page
      };
      if (this.caseList.length < this.TotalItems) {
        const res = await getCaseListApi(params);
        this.TotalItems = res.data.total;
        if (res?.data?.data?.length > 0) {
          this.caseList.push(...res?.data?.data);
        }
        this.loading = false;
      } else {
        this.finished = true;
      }

    },
  }
}
</script>

<style lang="scss">
.view-case {
  .header-img {
    margin-bottom: 0.69rem;
  }
  .center-content {
    padding: 0 3.1rem;
    box-sizing: border-box;
    .img-list {
      margin-bottom: 1.5rem;
      flex-wrap: wrap;
      .van-list__finished-text {
        width: 100%;
        text-align: center;
      }
      .van-list__loading {
        width: 100%;
        text-align: center;
      }
      .img-item {
        height: 3.06rem;
        width: 100%;
        position: relative;
        margin: 0 0.1rem;
        margin-bottom: 0.2rem;
        .title-box {
          position: absolute;
          width: 100%;
          // left: 50%;
          padding: 0rem 0.3rem;
          box-sizing: border-box;
          top: 50%;
          transform: translate(0%, -50%);
          .title {
            padding: 0.08rem 0.1rem;
            min-width: 70%;
            text-align: center;
            line-height: 0.44rem;
            background: #000;
            color: #fff;
            font-size: 0.2rem;
            opacity: 0;
          }
        }
      }
      .img-item:nth-child(3n + 1) {
        width: 30%;
        flex-shrink: 0;
      }
      .img-item:nth-child(3n + 2) {
        // width: 30%;
        // flex-shrink: 0;
        flex-grow: 1;
        max-width: 34%;
      }
      .img-item:nth-child(3n + 3) {
        width: 30%;
        flex-shrink: 0;
      }
      .img-item:hover {
        .title {
          opacity: 1;
        }
      }
    }
  }
  .case-detail {
    width: 11.78rem;
    height: 5.41rem;
    background: #ffffff;
    box-shadow: 0px 0.04rem 0.2rem 0.1rem rgba(0, 0, 0, 0.25);
    border-radius: 0.12rem;
    padding: 0.6rem;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .left-banner {
      width: 7.62rem;
      height: 4.21rem;
      flex-shrink: 0;
      margin-right: 0.55rem;
      .banner-list {
        width: 100%;
        height: 100%;
        .el-carousel__container {
          width: 100%;
          height: 100%;
          .el-carousel__arrow--left {
            @include pcurl("pc-banner-arrow.png");
            transform: translateY(-50%) rotate(180deg);
            i {
              display: none;
            }
          }
          .el-carousel__arrow--right {
            @include pcurl("pc-banner-arrow.png");
            i {
              display: none;
            }
          }
        }
        .el-carousel__indicators {
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0.22rem;
          display: none;
        }
        .el-carousel__indicator {
          display: flex;
          align-items: center;
          justify-content: center;
          .el-carousel__button {
            width: 0.24rem;
            height: 0.24rem;
            border-radius: 50%;
            opacity: 1;
            background: unset;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .el-carousel__button::after {
            content: "";
            display: block;
            width: 60%;
            height: 60%;
            border-radius: 50%;
            background: rgba(175, 175, 175, 1);
            border: 0.03rem solid unset;
          }
        }
        .is-active {
          .el-carousel__button {
            background: #fff;
          }
          .el-carousel__button::after {
            content: "";
            display: block;
            width: 60%;
            height: 60%;
            border-radius: 50%;
            background: #fff;
            border: 0.03rem solid rgba(217, 217, 217, 1);
          }
        }
      }
    }
    .right-content {
      flex-grow: 1;
      .c-title {
        font-size: 0.24rem;
        font-weight: 600;
        margin-bottom: 0.3rem;
      }
      .c-line {
        height: 0.02rem;
        background: rgba(238, 245, 238, 1);
        margin-bottom: 0.3rem;
      }
      .c-text {
        font-size: 0.2rem;
        font-weight: 300;
      }
    }
    .close-icon {
      position: absolute;
      width: 0.29rem;
      height: 0.29rem;
      right: 0.15rem;
      top: 0.15rem;
    }
  }
}
</style>